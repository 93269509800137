import React from 'react';
import cn from 'classnames';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { OneColumn } from '../../components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';
import styles from './order.module.scss';

export function Head() {
  return (
    <>
      <SEO
        title="Заказ отмене"
        description="Нам жаль, что так вышло. По каким-то причинам заказ был отменён, но мы надеемся, что вы ещё не раз вернетесь к нам."
      />
      <YandexTableau />
    </>
  );
}

function OrderApprovedPage() {
  return (
    <Layout>
      <OneColumn>
        <div className={styles.block}>
          <h1 className={cn({ [styles.header]: true, [styles.error]: true })}>Заказ отменен</h1>
          <p className={styles.description}>
            Нам жаль, что так вышло. По каким-то причинам заказ был отменён, но мы надеемся, что вы
            ещё не раз вернетесь к нам.
          </p>
        </div>
      </OneColumn>
    </Layout>
  );
}

export default OrderApprovedPage;
